<template>
  <div id="preview">
    <v-toolbar color="#8950FC">
      <v-toolbar-title>
        <h1 class="text-white">{{ pageTitle }}</h1>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card>
      <!-- <v-card-title>
          <span class="headline"> {{ pageTitle }} </span>
        </v-card-title> -->
      <!-- <v-card-subtitle class="text-h5">
          <h5>{{ pageDescription }}</h5>
        </v-card-subtitle> -->
      <v-card-text>
        <!-- <small>*indicates required field</small> -->
        <v-container v-if="SearchFlag">
          <div class="d-flex justify-content-center mb-3">
            <b-spinner
              variant="primary"
              type="grow"
              label="Loading..."
            ></b-spinner>
          </div>
        </v-container>
        <v-container v-if="OtpSendFlag == false">
          <v-card>
            <v-form
              ref="form1"
              v-model="valid1"
              lazy-validation
              v-on:submit.prevent="sendOtp"
            >
              <v-card-title>
                <span class="headline">Member Details</span>
              </v-card-title>
              <v-card-text>
                <!-- <small>*indicates required field</small> -->
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <label>Membership Id</label>
                    <v-text-field
                      v-model="MembershipId"
                      :rules="MembershipIdRules"
                      hint="Enter your membership id"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <label>Mobile No</label>
                    <v-text-field
                      v-model="MobileNo"
                      :rules="MobileNoRules"
                      hint="Enter mobile number to send OTP"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col align="center" cols="12" sm="12" md="12">
                    <v-btn
                      type="submit"
                      :disabled="!valid1"
                      @click.prevent="sendOtp"
                      :loading="SendOtpLoadingFlag"
                      target="_blank"
                      color="#ff7f50"
                      class="text-white"
                    >
                      <!-- <v-icon dark> mdi-file-excel </v-icon> -->
                      Send OTP
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-container>

        <v-container v-if="OtpSendFlag == true && VerifyOtpFlag == false">
          <v-card>
            <v-form
              ref="form2"
              v-model="valid2"
              lazy-validation
              v-on:submit.prevent="verifyOtp"
            >
              <v-card-title>
                <span class="headline">Verify OTP</span>
              </v-card-title>
              <v-card-text>
                <!-- <small>*indicates required field</small> -->
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <label>OTP</label>
                    <v-text-field
                      v-model="VerificationCode"
                      :rules="VerificationCodeRules"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col align="center" cols="12" sm="12" md="12">
                    <v-btn
                      type="submit"
                      :disabled="!valid2"
                      @click.prevent="verifyOtp"
                      :loading="VerifyOtpLoadingFlag"
                      target="_blank"
                      color="#8950FC"
                      class="text-white"
                    >
                      <!-- <v-icon dark> mdi-file-excel </v-icon> -->
                      Submit
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-container>

        <v-container
          v-if="
            OtpSendFlag == true && VerifyOtpFlag == true && SubmitFlag == false
          "
        >
          <v-card>
            <v-form
              ref="form3"
              v-model="valid3"
              lazy-validation
              v-on:submit.prevent="submitForm"
            >
              <v-card-title>
                <span class="headline">Change Password</span>
              </v-card-title>
              <v-card-text>
                <v-container v-if="NotesFlag">
                  <v-row wrap>
                    <v-col align="start" cols="12" md="12">
                      <p style="font-size: 14px; font-weight: bold">Notes:</p>
                      <ol style="font-size: 14px; font-weight: bold">
                        <li>
                          Password lenght should be atleast six characters.
                        </li>
                      </ol>
                    </v-col>
                  </v-row>
                </v-container>
                <!-- <small>*indicates required field</small> -->
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <label>New Password</label>
                    <v-text-field
                      type="password"
                      v-model="NewPassword"
                      :rules="NewPasswordRules"
                      hint="Password length should be atleast six characters"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <label>Confirm Password</label>
                    <v-text-field
                      type="password"
                      v-model="ConfirmPassword"
                      :rules="ConfirmPasswordRules"
                      hint="Password length should be atleast six characters"
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col align="center" cols="12" sm="12" md="12">
                    <v-btn
                      type="submit"
                      :disabled="!valid3"
                      @click.prevent="submitForm"
                      :loading="SubmitLoadingFlag"
                      target="_blank"
                      color="#008000"
                      class="text-white"
                    >
                      <!-- <v-icon dark> mdi-file-excel </v-icon> -->
                      Submit
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <!-- <v-btn
            @click="closePrompt"
            elevation="30"
            shaped
            tile
            small
            color="#a52a2a"
            class="font-size-h6 px-10 mr-3 white--text"
          >
            Close
          </v-btn> -->
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  props: {
    addRecordPrompt: {
      type: Boolean,
      required: true,
    },
    pageId: {
      required: false,
    },
  },
  data() {
    return {
      valid1: true,
      valid2: true,
      valid3: true,

      LoadingFlag: false,
      SearchFlag: false,

      NotesFlag: true,

      OtpSendFlag: false,
      VerifyOtpFlag: false,
      SubmitFlag: false,

      SendOtpLoadingFlag: false,
      VerifyOtpLoadingFlag: false,
      SubmitLoadingFlag: false,

      pageTitle: "Activate Account",
      pageDescription: "",

      pageContent: "",

      rows: [],

      MembershipId: "",
      MembershipIdRules: [(v) => !!v || "Membership Id is required"],

      MobileNo: "",
      MobileNoRules: [(v) => !!v || "Mobile number is required"],

      VerificationCode: "",
      VerificationCodeRules: [(v) => !!v || "OTP is required"],

      NewPassword: "",
      NewPasswordRules: [(v) => !!v || "New password is required"],

      ConfirmPassword: "",
      ConfirmPasswordRules: [(v) => !!v || "Confirm password is required"],
    };
  },
  computed: {},
  watch: {},
  created() {
    // this.initialize()
  },
  methods: {
    validateForm() {
      console.log("validateForm called");
      this.$refs.form1.validate();
    },
    resetForm() {
      console.log("resetForm called");
      this.$refs.form1.reset();
      //   this.$refs.ProfilePicture.reset();
    },
    resetValidation() {
      console.log("resetValidation called");
      this.$refs.form1.resetValidation();
    },
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideAddRecordPrompt");
    },
    refreshPageData() {
      console.log("refreshPageData called");
      var pageId = this.pageId;
      console.log("pageId=" + pageId);
      //   this.getMemberDetails();
    },
    sendOtp() {
      console.log("sendOtp is called");

      var validate = this.$refs.form1.validate();
      console.log("validate=" + validate);
      /*
      validate = false;
      this.SendOtpLoadingFlag = true;
      setTimeout(() => {
        this.SendOtpLoadingFlag = false;
        this.OtpSendFlag = true;
      }, 2000);
      */

      if (validate == true) {
        this.SendOtpLoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/users/send-otp";
        var upload = {
          UserInterface: 2,
          MembershipId: this.MembershipId,
          MobileNo: this.MobileNo,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.SendOtpLoadingFlag = false;
            // thisIns.ShowFormFlag = true;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.sweetAlert("success", output, false);
              thisIns.OtpSendFlag = true;
              /*
              thisIns.pageTitle = records.PagesTitle;
              thisIns.pageDescription = records.PagesDescription;
              thisIns.pageContent = records.PagesContent;
              */
              // thisIns.toast("success", output);
              // thisIns.resetForm();
            } else {
              // thisIns.MemberTypeId = 23;
              thisIns.sweetAlert("error", output, false);
              // thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SendOtpLoadingFlag = false;
          });
      } else {
        var message = "";
        if (validate == false) {
          message += "Kindly fill the required fields. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
    verifyOtp() {
      console.log("verifyOtp is called");

      var validate = this.$refs.form2.validate();
      console.log("validate=" + validate);
      /*
      // validate = false;
      this.VerifyOtpLoadingFlag = true;
      setTimeout(() => {
        // Remove page loader after some time
        this.VerifyOtpLoadingFlag = false;
        this.VerifyOtpFlag = true;
      }, 2000);
      */

      if (validate == true) {
        this.VerifyOtpLoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/users/verify-otp";
        var upload = {
          UserInterface: 2,
          Username: this.MembershipId,
          OtpNo: this.VerificationCode,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.VerifyOtpLoadingFlag = false;
            // thisIns.ShowFormFlag = true;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.sweetAlert("success", output, false);
              thisIns.VerifyOtpFlag = true;
              // thisIns.toast("success", output);
              // thisIns.resetForm();
            } else {
              thisIns.sweetAlert("error", output, false);
              // thisIns.MemberTypeId = 23;
              // thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.VerifyOtpLoadingFlag = false;
          });
      } else {
        var message = "";
        if (validate == false) {
          message += "Kindly fill the required fields. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
    submitForm() {
      console.log("submitForm is called");

      var validate = this.$refs.form3.validate();
      console.log("validate=" + validate);
      /*
      validate = false;
      this.SubmitLoadingFlag = true;
      setTimeout(() => {
        // Remove page loader after some time
        this.SubmitLoadingFlag = false;
        this.SubmitFlag = true;
      }, 2000);
      */

      if (validate == true) {
        this.SubmitLoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/users/update-password";
        var upload = {
          UserInterface: 2,
          Username: this.MembershipId,
          MobileNo: this.MobileNo,
          NewPassword: this.NewPassword,
          ConfirmPassword: this.ConfirmPassword,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.SubmitLoadingFlag = false;
            // thisIns.ShowFormFlag = true;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              // thisIns.toast("success", output);
              thisIns.sweetAlert("success", output, false);
              thisIns.closePrompt();
            } else {
              // thisIns.MemberTypeId = 23;
              // thisIns.toast("error", output);
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SubmitLoadingFlag = false;
          });
      } else {
        var message = "";
        if (validate == false) {
          message += "Kindly fill the required fields. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
